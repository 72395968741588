<template>
  <!-- Main content -->
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header with-border content">
            <h2>{{ locale === 'en_US' ? 'Math' : 'Maths' }} Resources</h2>
            <p>Click-and-go lesson presentations that follow a mastery approach, similar to schemes including Maths No Problem, White Rose Maths and Mathematics Mastery. Lesson PowerPoints will not necessarily take exactly one lesson to cover, some may require more or less time. Blocks will be released through the year. </p>
            <p><b>N.B. Only the first lesson of each block is available without a paid {{ locale === 'en_US' ? 'Math' : 'Maths' }} Shed subscription.</b></p>
          </div>
          <div class="box-body">
            <!-- <div class="content" v-if="!hasAccess">
                  <h4>View Samples:</h4>
                  <p>N.B. A full paying subscription is requred to access further teaching slides.</p>
                  <p>Year 1,  Autumn Block 1 (Place Value), Lesson 1 (To be able to sort objects) <router-link class="is-pulled-right button is-warning" to="resources/21">View</router-link></p>
                  <p>Year 2,  Autumn Block 1 (Place Value), Lesson 1 (To be able to count objects to 100) <router-link class="is-pulled-right button is-warning" to="resources/36">View</router-link></p>
                  <p>Year 3,  Autumn Block 1 (Place Value), Lesson 1 (To be able to count in 100s to 1000) <router-link class="is-pulled-right button is-warning" to="resources/48">View</router-link></p>
                  <p>Year 4,  Autumn Block 1 (Place Value), Lesson 1 (To be able to explore Roman Numerals up to 100 / C) <router-link class="is-pulled-right button is-warning" to="resources/58">View</router-link></p>
                  <p>Year 5,  Autumn Block 1 (Place Value), Lesson 1 (To be able to explore numbers up to ten thousand) <router-link class="is-pulled-right button is-warning" to="resources/71">View</router-link></p>
                  <p>Year 6,  Autumn Block 1 (Place Value), Lesson 1 (To be able to explore numbers up to ten million) <router-link class="is-pulled-right button is-warning" to="resources/83">View</router-link></p>
                </div> -->
            <!-- <div v-if="nodes" class="resourcePanel">
                <RecursiveList :level="0" :nodes="nodes" :parent="null" v-if="nodes" v-for="node in nodes.filter(n => n.parent_id == null)" :node="node" :key="'lst' + node.id" />
              </div> -->
            <p><b>Our teaching resources are now available on the Maths Shed  website.</b></p>
            <p><a class="button is-large is-link" href="https://www.mathshed.com/en-gb/browse">View Resources</a></p>
          </div>
          <p class="small">
            These resources are copyright &copy; Education Shed Ltd and is for use solely by subscribing schools. Any element of this scheme may not be distributed for any purposes and any person doing so may have their account suspended and legal action taken against them.
          </p>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>
import $ from 'jquery'
import RecursiveList from '@/components/views/components/RecursiveList'
import { request } from '@/edshed-common/api'

export default {
  name: 'MathsSchemeView',
  components: {
    RecursiveList
  },
  data () {
    return {
      curricula: null,
      selectedCurriculum: 0,
      nodes: null
    }
  },
  computed: {
    isTrial () {
      return this.$store.getters.isNumberTrial
    },
    hasAccess () {
      if (!this.$store.state.user) {
        return false
      }
      if (this.$store.state.user.superuser) {
        return true
      }
      if (this.$store.getters.isNumberTrial) {
        return false
      }
      if (this.$store.getters.hasNumber) {
        return true
      }
      return false
    },
    locale () {
      if (this.$store.state.user) {
        if (this.$store.state.user.school) {
          return this.$store.state.user.school.locale
        }
        return this.$store.state.user.locale
      }
      return navigator.language.replace('-', '_')
    }
  },
  mounted () {
    this.$nextTick(() => {
      // this.getCurricula()
    })
  },
  methods: {
    toggle (id) {
      console.log('toggle')
      $('#node-' + id).toggle()
    },
    getCurricula () {
      request('GET', 'quiz/curricula?pack_id=1', null)
        .then((response) => {
          const data = response.data
          this.curricula = data.curricula.items
          console.log(data)
          this.getNodes(this.curricula[0].id)
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getNodes () {
      request('GET', 'quiz/curriculum_nodes?curriculum_id=1', null)
        .then((response) => {
          const data = response.data
          this.nodes = data.nodes.items
          console.log(data)
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    }
  }
}
</script>
<style scoped>
  .table>thead:first-child>tr:first-child>th, .table>thead>tr>th {
    border-top:1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
  }
</style>
