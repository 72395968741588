<template>
  <ul v-if="nodes.filter(n => n.parent_id == node.id).length > 0 || node.resource_ids.length" :style="{'display': (parent == null ? 'block' : 'none')}" :class="node.parent_id == null ? 'nav-outer ' + thisClass + ' ' + selfClass:thisClass + ' ' + selfClass">
    <li>
      <a v-if="nodes.filter(n => n.parent_id == node.id).length > 0" :style="'margin-left: ' + (level * 10) + 'px;'" :class="{'nav-heading': node.resource_ids.length == 0}" href="#" @click.prevent="toggle(node.id)">
        <span v-if="!node.resource_ids.length" class="icon"><i class="ion ion-ios-arrow-down" /></span> <span>{{ node.name }}</span>
      </a>
      <p v-else :style="'margin-left: ' + (level * 10) + 'px;'">
        {{ node.name }}
        <span v-for="res in node.resources">
          <router-link :key="'lnk-' + res.id" class="button is-pulled-right is-small is-link" :to="'/resources/' + res.id">
            <span>Teaching Slides</span> <span class="icon"><i class="ion ion-ios-arrow-forward" /></span>
          </router-link>
          <span v-if="res.availability == 'free'" class="tag is-success is-pulled-right" style="margin-right: 5px;">FREE</span>
        </span>
      </p>
    </li>
    <div v-if="nodes">
    <recursive-list
      v-for="ynode in nodes.filter(n => n.parent_id === node.id)"
      :key="'lst-' + ynode.id"
      :nodes="nodes"
      :label="ynode.name"
      :parent="node.id"
      :node="ynode"
      :level="level + 1"
    />
    </div>
  </ul>
</template>
<script>
export default {
  name: 'RecursiveList',
  props: {
    node: null,
    nodes: null,
    parent: null,
    level: 0
  },
  data () {
    return {
      nodeData: []
    }
  },
  computed: {
    thisClass () {
      return 'node-' + this.node.parent_id
    },
    selfClass () {
      return 'nodeself-' + this.node.id
    }
  },
  methods: {
    toggle (id) {
      console.log('toggle')
      $('.node-' + id).slideToggle()
      $('.nodeself-' + id).toggleClass('open')
    }
  }
}
</script>
  <style scoped>
    ul {
      margin-left: 0;
    }
    ul > li > a > span > i.ion-ios-arrow-down {
      transform: rotate(0deg);
      transition: transform 0.25s linear;
    }
    ul.open > li > a > span > i.ion-ios-arrow-down {
      transform: rotate(-180deg);
      transition: transform 0.25s linear;
    }
    li {
      list-style-type: none;
      width: 100%
    }
    .nav-outer li {
      padding: 0.75em 1em;
      border: 1px solid #eee;

      font-size: 1em;
    }
    .nav-outer li a {
      /* color: #222; */
    }
    .nav-outer > li {
      padding: 0.5em 1em;
      border: 1px solid #eee;
      background-color: #f8f8f8;
      font-size: 1.4em;
    }
  </style>
